import React, { useState } from "react";
import { toast } from "react-toastify";

const Contact = ({ isDarkMode }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);
    const body = {
      name,
      email,
      subject,
      message,
    };

    toast
      .promise(
        fetch(
          "https://script.google.com/macros/s/AKfycbzUUC3lP3FhejKav0iVCFD2n08ilno9Hna6Dy5x2QDEBNRP8LacASz3mjL9VtCg7dug/exec",
          {
            redirect: "follow",
            method: "POST",
            body: JSON.stringify(body),
          }
        ),
        {
          pending: "Sending mail...",
          success: "Mail sent!",
          error: "Failed to send mail",
        }
      )
      .finally(() => {
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setIsSending(false);
      });
  };

  return (
    <section
      className="paralax-mf footer-paralax bg-image sect-mt4 route"
      style={{
        backgroundColor: isDarkMode ? "#1E2021" : "#F5F5F5",
        transition: "all 0.5s ease",
        marginTop: 0,
      }}
    >
      <div
        className="overlay-mf"
        style={{
          backgroundColor: isDarkMode ? "#1E2021" : "#F5F5F5",
          transition: "all 0.5s ease",
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="contact-mf">
              <div
                id="contact"
                className="box-shadow-full"
                style={{
                  backgroundColor: isDarkMode ? "#181A1B" : "",
                  transition: "all 0.5s ease",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="title-box-2">
                      <h5
                        className="title-left"
                        style={{ color: isDarkMode ? "white" : "" }}
                      >
                        Contact
                      </h5>
                    </div>
                    <div>
                      <form
                        /*
                          action="https://script.google.com/macros/s/AKfycbwtxfUm35D7aQIGahE20Jb65w-1WBi7Vi_eKZu6KrzOnkUYllRVA6ydbsjONUnLOCed/exec"
                          method="POST"*/
                        onSubmit={handleSubmit}
                        className="contactForm"
                      >
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                style={
                                  isDarkMode
                                    ? {
                                        backgroundColor: "#181A1B",
                                        borderColor: "rgb(60, 65, 68)",
                                        transition: "all 0.5s ease",
                                      }
                                    : {}
                                }
                                type="text"
                                name="name"
                                className="form-control"
                                id="name"
                                placeholder="Your Name"
                                minLength="4"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                style={
                                  isDarkMode
                                    ? {
                                        backgroundColor: "#181A1B",
                                        borderColor: "rgb(60, 65, 68)",
                                        transition: "all 0.5s ease",
                                      }
                                    : {}
                                }
                                name="email"
                                id="email"
                                placeholder="Your Email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                style={
                                  isDarkMode
                                    ? {
                                        backgroundColor: "#181A1B",
                                        borderColor: "rgb(60, 65, 68)",
                                        transition: "all 0.5s ease",
                                      }
                                    : {}
                                }
                                name="subject"
                                id="subject"
                                placeholder="Subject"
                                minLength="4"
                                required
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                style={
                                  isDarkMode
                                    ? {
                                        backgroundColor: "#181A1B",
                                        borderColor: "rgb(60, 65, 68)",
                                        transition: "all 0.5s ease",
                                      }
                                    : {}
                                }
                                name="message"
                                rows="5"
                                placeholder="Message"
                                minLength="10"
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              type="submit"
                              className="button button-a button-big button-rouded"
                              disabled={isSending}
                              style={{
                                borderColor: "#0078ff",
                                backgroundColor: isSending ? "gray" : "",
                                transition: "all .5s ease",
                              }}
                            >
                              Send Mail
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="title-box-2 pt-4 pt-md-0">
                      <h5
                        className="title-left"
                        style={{ color: isDarkMode ? "white" : "" }}
                      >
                        Do Not Hesitate!
                      </h5>
                    </div>
                    <div className="more-info">
                      <p
                        className="lead"
                        style={{ color: isDarkMode ? "#B7B0A7" : "" }}
                      >
                        Do not hesitate to contact me!
                        <br />
                        <br />
                        It would be my pleasure to respond to any questions /
                        job offers you may have.
                        <br />
                        <br />
                        Use the form to send me a mail.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright-box"></div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Contact;
