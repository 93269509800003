import React from "react";

const languages = [
  {
    id: "ENGLISH_language",
    content: "English",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "FRENCH_language",
    content: "French",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "DUTCH_language",
    content: "Dutch",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "JAPANESE_language",
    content: "Japanese",
    porcentage: "50%",
    value: "50",
  },
  {
    id: "ARABIC_language",
    content: "Arabic",
    porcentage: "20%",
    value: "20",
  },
];

const codingLanguages = [
  {
    id: "JAVA_skill",
    content: "Java",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "JAVASCRIPT_skill",
    content: "Javascript",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "HTML_skill",
    content: "HTML",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "CSS_skill",
    content: "CSS",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "CS_skill",
    content: "C#",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "SQL_skill",
    content: "SQL",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "PHP_skill",
    content: "PHP",
    porcentage: "70%",
    value: "70",
  },
  {
    id: "CPP_skill",
    content: "C++",
    porcentage: "40%",
    value: "40",
  },
  {
    id: "PYTHON_skill",
    content: "Python",
    porcentage: "30%",
    value: "30",
  },
];

const skills = [
  {
    id: "REACT_skill",
    content: "React JS",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "SPRINGBOOT_skill",
    content: "Spring Boot",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "REACTNATIVE_skill",
    content: "React Native",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "GRAPHQL_skill",
    content: "GraphQL",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "MICROSERVICES_skill",
    content: "Microservices",
    porcentage: "100%",
    value: "100",
  },
  {
    id: "AWS_skill",
    content: "AWS",
    porcentage: "90%",
    value: "90",
  },
  {
    id: "DOTNET_skill",
    content: ".NET Framework",
    porcentage: "85%",
    value: "85",
  },
  {
    id: "ASPDOTNET_skill",
    content: "ASP .NET",
    porcentage: "75%",
    value: "75",
  },
  {
    id: "VUEJS_skill",
    content: "VueJS",
    porcentage: "50%",
    value: "50",
  },
  {
    id: "ANGULARJS_skill",
    content: "AngularJS",
    porcentage: "40%",
    value: "40",
  },
];

const aboutMe = [
  {
    id: "first-p-about",
    content:
      "Hello! My name is Anouar Dahmani, and I am a full stack developer with a bachelor's degree in Applied Information Technology living in Belgium. I have a solid understanding of computer science concepts, with a strong focus on efficiency and quality and am specialized in Spring Boot and React.",
  },
  {
    id: "second-p-about",
    content:
      "I have been able to work with a variety of technologies in my early career as a full stack developer. In my previous role at Devoteam, I was responsible for building and maintaining web applications for a wide range of clients. I have experience with popular frameworks such as Spring Boot, React, and React Native. This has given me the possibility to start my own business and develop my own applications as a freelancer.",
  },
  {
    id: "third-p-about",
    content:
      "I am a self-starter and enjoy taking on complex problems and finding creative solutions. Although I prefer to work independently, I am a strong collaborator and am always willing to contribute my ideas and skills to a team.",
  },
  {
    id: "fourth-p-about",
    content: "Thank you for considering me for your project!",
  },
];

const About = ({ isDarkMode }) => {
  return (
    <section
      id="about"
      className="about-mf sect-pt4 route"
      style={{
        backgroundColor: isDarkMode ? "#1E2021" : "",
        transition: "all 0.5s ease",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div
              className="box-shadow-full"
              style={{
                backgroundColor: isDarkMode ? "#181A1B" : "",
                color: isDarkMode ? "white" : "",
                transition: "all 0.5s ease",
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="title-box-2">
                    <h5
                      className="title-left"
                      style={{
                        color: isDarkMode ? "white" : "",
                        transition: "all 0.5s ease",
                      }}
                    >
                      My Skills
                    </h5>
                  </div>
                  <div className="skill-mf">
                    <p
                      className="title-s"
                      style={{
                        color: isDarkMode ? "white" : "",
                        transition: "all 0.5s ease",
                      }}
                    >
                      Frameworks
                    </p>
                    {skills.map((skill) => {
                      return (
                        <React.Fragment key={skill.id}>
                          <span
                            style={{
                              color: isDarkMode ? "rgb(183, 176, 167)" : "",
                              transition: "all 0.5s ease",
                            }}
                          >
                            {skill.content}
                          </span>{" "}
                          <span
                            className="pull-right"
                            style={{
                              color: isDarkMode ? "rgb(183, 176, 167)" : "",
                              transition: "all 0.5s ease",
                            }}
                          >
                            {skill.porcentage}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: skill.porcentage }}
                              aria-valuenow={skill.value}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="skill-mf" style={{ marginTop: 50 }}>
                    <p
                      className="title-s"
                      style={{
                        color: isDarkMode ? "white" : "",
                        transition: "all 0.5s ease",
                      }}
                    >
                      Coding Languages
                    </p>
                    {codingLanguages.map((codingLanguage) => {
                      return (
                        <React.Fragment key={codingLanguage.id}>
                          <span
                            style={{
                              color: isDarkMode ? "rgb(183, 176, 167)" : "",
                              transition: "all 0.5s ease",
                            }}
                          >
                            {codingLanguage.content}
                          </span>{" "}
                          <span
                            className="pull-right"
                            style={{
                              color: isDarkMode ? "rgb(183, 176, 167)" : "",
                              transition: "all 0.5s ease",
                            }}
                          >
                            {codingLanguage.porcentage}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: codingLanguage.porcentage }}
                              aria-valuenow={codingLanguage.value}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-me pt-4 pt-md-0">
                    <div className="title-box-2">
                      <h5
                        className="title-left"
                        style={{
                          color: isDarkMode ? "white" : "",
                          transition: "all 0.5s ease",
                        }}
                      >
                        About Me
                      </h5>
                    </div>
                    {aboutMe.map((content) => {
                      return (
                        <p className="lead" key={content.id}>
                          {content.content}
                        </p>
                      );
                    })}
                    <div className="skill-mf" style={{ marginTop: 79 }}>
                      <p
                        className="title-s"
                        style={{
                          color: isDarkMode ? "white" : "",
                          transition: "all 0.5s ease",
                        }}
                      >
                        Languages
                      </p>
                      {languages.map((language) => {
                        return (
                          <React.Fragment key={language.id}>
                            <span
                              style={{
                                color: isDarkMode ? "rgb(183, 176, 167)" : "",
                                transition: "all 0.5s ease",
                              }}
                            >
                              {language.content}
                            </span>{" "}
                            <span
                              className="pull-right"
                              style={{
                                color: isDarkMode ? "rgb(183, 176, 167)" : "",
                                transition: "all 0.5s ease",
                              }}
                            >
                              {language.porcentage}
                            </span>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: language.porcentage }}
                                aria-valuenow={language.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
