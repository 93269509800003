import React, { useState, useEffect } from "react";
import $ from "jquery";

import logo1 from "../img/male1.png";
import logo2 from "../img/male.png";
import { DarkModeSwitch } from "react-toggle-dark-mode";

const Navbar = ({ isDarkMode, handleDarkModeChange }) => {
  const [logo, setLogo] = useState(logo1);
  const [shouldBeTop, setShouldBeTop] = useState(false);

  const handleResize = () => {
    if (
      (window.innerWidth <= 600 || window.innerWidth >= 1500) &&
      !shouldBeTop
    ) {
      setShouldBeTop(true);
    } else if (
      window.innerWidth > 600 &&
      window.innerWidth < 1500 &&
      shouldBeTop
    ) {
      setShouldBeTop(false);
    }
  };

  useEffect(() => {
    const nav = $("nav");
    let navHeight = nav.outerHeight();

    $(".navbar-toggler").on("click", function () {
      if (!$("#mainNav").hasClass("navbar-reduce")) {
        $("#mainNav").addClass("navbar-reduce");
      }
    });

    $("body").scrollspy({
      target: "#mainNav",
      offset: navHeight,
    });

    $(".js-scroll").on("click", function () {
      $(".navbar-collapse").collapse("hide");
    });

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        document
          .querySelector(".navbar-expand-md")
          .classList.add("navbar-reduce");
        document
          .querySelector(".navbar-expand-md")
          .classList.remove("navbar-trans");
        setLogo(logo2);
      } else {
        document
          .querySelector(".navbar-expand-md")
          .classList.add("navbar-trans");
        document
          .querySelector(".navbar-expand-md")
          .classList.remove("navbar-reduce");
        setLogo(logo1);
      }
    });

    $('a.js-scroll[href*="#"]:not([href="#"])').on("click", function () {
      if (
        window.location.pathname.replace(/^\//, "") ===
          this.pathname.replace(/^\//, "") &&
        window.location.hostname === this.hostname
      ) {
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          $("html, body").animate(
            {
              scrollTop: target.offset().top - navHeight + 5,
            },
            1000,
            "easeInExpo"
          );
          return false;
        }
      }
    });

    $(".js-scroll").on("click", function () {
      $(".navbar-collapse").collapse("hide");
    });

    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [shouldBeTop]);

  return (
    <nav
      className="navbar navbar-b navbar-trans navbar-expand-md fixed-top"
      style={{
        backgroundColor:
          logo === logo1 ? "transparent" : isDarkMode ? "#212324" : "white",
      }}
      id="mainNav"
    >
      <div className="container">
        <a className="navbar-brand js-scroll" href="#page-top">
          <img src={logo} alt="logo" style={{ maxWidth: "100px" }} />
        </a>
        {!shouldBeTop ? (
          <DarkModeSwitch
            style={{
              borderRadius: 25,
              transition: "background-color 0.5s ease",
              padding: 5,
            }}
            checked={isDarkMode}
            onChange={handleDarkModeChange}
            size={50}
          />
        ) : (
          <DarkModeSwitch
            style={{
              borderRadius: 25,
              transition: "background-color 0.5s ease, top 0.7s ease",
              padding: 5,
              position: "absolute",
              right: 100,
              top: logo === logo1 ? 55 : 45,
            }}
            checked={isDarkMode}
            onChange={handleDarkModeChange}
            size={50}
          />
        )}
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarDefault"
          aria-controls="navbarDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div
          className="navbar-collapse collapse justify-content-end"
          id="navbarDefault"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link js-scroll active" href="#home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll" href="#about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll" href="#experience">
                Experience
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
