import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaBookReader, FaGlasses, FaNodeJs } from "react-icons/fa";
import { SiSpringboot, SiGraphql } from "react-icons/si";
import {
  GiStarFormation,
  GiStarsStack,
  GiCommercialAirplane,
} from "react-icons/gi";

const Experience = ({ isDarkMode }) => {
  const experienceItems = [
    {
      name: "Freelancer",
      tags: "SpringBoot ReactJS",
      role: "Full stack developer",
      date: "2023 - present",
      icon: <GiCommercialAirplane />,
      contentStyle: {
        background: isDarkMode ? "#0a6ab6" : "rgb(33, 150, 243)",
        color: "#fff",
      },
      contentArrowStyle: {
        borderRight: isDarkMode
          ? "7px solid #0a6ab6"
          : "7px solid  rgb(33, 150, 243)",
      },
      iconStyle: {
        background: isDarkMode ? "#0a6ab6" : "rgb(33, 150, 243)",
        color: "#fff",
      },
    },
    {
      name: "CEO",
      tags: "SpringBoot ReactJS ReactNative AWS WebHosting MailHosting",
      role: "Inspector Pro / Full Stack Developer",
      date: "2021 - present",
      icon: <GiStarFormation />,
      contentStyle: {
        background: isDarkMode ? "#0a6ab6" : "rgb(33, 150, 243)",
        color: "#fff",
      },
      contentArrowStyle: {
        borderRight: isDarkMode
          ? "7px solid #0a6ab6"
          : "7px solid  rgb(33, 150, 243)",
      },
      iconStyle: {
        background: isDarkMode ? "#a38524" : "#d4af37",
        color: "#fff",
        borderBlockColor: "green",
      },
    },
    {
      name: "Telenet",
      tags: "GraphQL",
      role: "Query Developer",
      date: "2020 - 2020",
      icon: <SiGraphql />,
      contentStyle: isDarkMode ? { background: "#181A1B", color: "#fff" } : {},
      contentArrowStyle: isDarkMode
        ? { borderRight: "7px solid  #181A1B" }
        : {},
      iconStyle: {
        background: isDarkMode ? "#bf1f8a" : "#E535AB",
        color: "#fff",
      },
    },
    {
      name: "Digipolis",
      tags: "NodeJS ExpressJS Kafka Microservices",
      role: "Back End Developer",
      date: "2020 - 2020",
      icon: <FaNodeJs />,
      contentStyle: isDarkMode ? { background: "#181A1B", color: "#fff" } : {},
      contentArrowStyle: isDarkMode
        ? { borderRight: "7px solid  #181A1B" }
        : {},
      iconStyle: {
        background: isDarkMode ? "#215732" : "#44883e",
        color: "#fff",
      },
    },
    {
      name: "KULeuven",
      tags: "SpringBoot ReactJS MySQL GraphQL",
      role: "Full Stack Developer",
      date: "2020 - 2020",
      icon: <GiStarsStack />,
      contentStyle: isDarkMode ? { background: "#181A1B", color: "#fff" } : {},
      contentArrowStyle: isDarkMode
        ? { borderRight: "7px solid  #181A1B" }
        : {},
      iconStyle: {
        background: isDarkMode ? "#a38524" : "#d4af37",
        color: "#fff",
      },
    },
    {
      name: "VLAIO",
      tags: "SpringBoot JSP MySQL",
      role: "Back End Developer",
      date: "2019 - 2020",
      icon: <SiSpringboot />,
      contentStyle: isDarkMode ? { background: "#181A1B", color: "#fff" } : {},
      contentArrowStyle: isDarkMode
        ? { borderRight: "7px solid  #181A1B" }
        : {},
      iconStyle: {
        background: isDarkMode ? "#3e6b22" : "#6EB442",
        color: "#fff",
      },
    },
    {
      name: "Devoteam",
      tags: "IT Consultancy",
      role: "Full Stack Developer",
      date: "2019 - 2021",
      icon: <GiStarsStack />,
      contentStyle: isDarkMode ? { background: "#181A1B", color: "#fff" } : {},
      contentArrowStyle: isDarkMode
        ? { borderRight: "7px solid  #181A1B" }
        : {},
      iconStyle: {
        background: isDarkMode ? "#a38524" : "#d4af37",
        color: "#fff",
      },
    },
    {
      name: "Devoteam (internship)",
      tags: "ReactJS SpringBoot MySQL Kafka Microservices",
      role: "Intern",
      date: "2019 - 2019",
      icon: <FaGlasses />,
      contentStyle: isDarkMode ? { background: "#181A1B", color: "#fff" } : {},
      contentArrowStyle: isDarkMode
        ? { borderRight: "7px solid  #181A1B" }
        : {},
      iconStyle: {
        background: isDarkMode ? "#c92f26" : "#EF3D33",
        color: "#fff",
      },
    },
    {
      name: "Odisee University College Brussels",
      tags: "Applied Information Technology bachelor's degree",
      role: "Student",
      date: "2016 - 2019",
      icon: <FaBookReader />,
      contentStyle: isDarkMode ? { background: "#181A1B", color: "#fff" } : {},
      contentArrowStyle: isDarkMode
        ? { borderRight: "7px solid  #181A1B" }
        : {},
      iconStyle: {
        background: isDarkMode ? "#0a6ab6" : "rgb(33, 150, 243)",
        color: "#fff",
      },
    },
  ];

  return (
    <section
      id="experience"
      className="portfolio-mf sect-pt4 route"
      style={{
        backgroundColor: isDarkMode ? "#1E2021" : "",
        transition: "all 0.5s ease",
      }}
    >
      <VerticalTimeline lineColor={isDarkMode ? "#181A1B" : undefined}>
        {experienceItems.map((ei) => (
          <VerticalTimelineElement
            key={ei.name}
            className="vertical-timeline-element--work"
            contentStyle={ei.contentStyle}
            contentArrowStyle={ei.contentArrowStyle}
            date={ei.date}
            dateClassName={isDarkMode ? "" : "verticalTimelineDate"}
            iconStyle={ei.iconStyle}
            icon={ei.icon}
          >
            <h3
              className="vertical-timeline-element-title"
              style={isDarkMode ? { color: "white" } : {}}
            >
              {ei.name}
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              style={isDarkMode ? { color: "white" } : {}}
            >
              {ei.role}
            </h4>
            <p style={isDarkMode ? { color: "#B7B0A7" } : {}}>{ei.tags}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </section>
  );
};

export default Experience;
