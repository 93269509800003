import React, { useState } from "react";

//import components
import Navbar from "./components/navbar.jsx";
import Intro from "./components/intro.jsx";
import About from "./components/about.jsx";
import Experience from "./components/experience";
import Contact from "./components/contact.jsx";
import BackToTop from "./components/back-top.jsx";
import Preloader from "./components/preloader";
import { ToastContainer } from "react-toastify";

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("isDarkMode") === "true"
  );

  const handleDarkModeChange = (isOn) => {
    localStorage.setItem("isDarkMode", isOn);
    setIsDarkMode(isOn);
  };

  return (
    <>
      <Navbar
        isDarkMode={isDarkMode}
        handleDarkModeChange={handleDarkModeChange}
      />
      <Intro />
      <About isDarkMode={isDarkMode} />
      <Experience isDarkMode={isDarkMode} />
      <Contact isDarkMode={isDarkMode} />
      <BackToTop />
      <Preloader />
      <ToastContainer
        position="bottom-left"
        theme={isDarkMode ? "dark" : "light"}
      />
    </>
  );
};

export default App;
